module.exports = {
  index: {
    reviews: {
      titlePartOne: "Vous aussi,",
      titlePartTwo: "soyez les premiers à comprendre... et à agir",
    },
  },
  read_more_link: "Lire la suite",
  prev_article: "Previous article",
  next_article: "Next article",
}
