import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/en"
import FeedbackPageTemplate from "../../templates/feedback-page"

const CheckoutSuccessPageEnglish = props => {
  const { title, subtitle } = props.data.markdownRemark.frontmatter

  return (
    <Layout location={props.location} title={title} description={subtitle}>
      <FeedbackPageTemplate {...props} />
    </Layout>
  )
}

export default CheckoutSuccessPageEnglish

export const pageQuery = graphql`
  query CheckoutSuccessPageEnglish {
    markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/checkout-success/index.en.md/" }
    ) {
      frontmatter {
        ...FeedbackPage
      }
    }
  }
`
