import React from "react"
import { graphql } from "gatsby"
import { Stack, Typography } from "@mui/material"
import LocalizedLink from "../components/LocalizedLink"
import { Buttons } from "../components"

const FeedbackPageTemplate = ({ data }) => {
  const {
    title,
    subtitle,
    button_title,
    link,
    text,
    contact_button_title,
    contact_link,
  } = data.markdownRemark.frontmatter

  return (
    <Stack
      component="section"
      px={2}
      mx="auto"
      maxWidth="830px"
      textAlign="center"
      minHeight="70vh"
      justifyContent="center"
      alignItems="center"
      spacing={4}
    >
      <Typography component="h1" variant="h2" color="#000065">
        {title}
        <Typography component="span" color="secondary" variant="h4">
          .
        </Typography>
      </Typography>
      <Typography pb={2}>{subtitle}</Typography>
      <LocalizedLink to={link} style={{ textDecoration: "none" }}>
        <Buttons.Primary title={button_title} />
      </LocalizedLink>
      <Typography>
        {text}{" "}
        <LocalizedLink to={contact_link} style={{ textDecoration: "none" }}>
          <Typography component="span">{contact_button_title}</Typography>
        </LocalizedLink>
      </Typography>
    </Stack>
  )
}

export default FeedbackPageTemplate

// export const ClientsSection = graphql``

export const query = graphql`
  fragment FeedbackPage on MarkdownRemarkFrontmatter {
    title
    subtitle
    button_title
    link
    text
    contact_button_title
    contact_link
  }
`
